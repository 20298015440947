<template>
  <div>
    <van-cell center style="background: #57c4b7">
      <template slot="title">
        <div style="display: flex; color: white">
          <div style="padding-right: 24px; border-right: 2px solid white">
            <span style="font-size: 18px">{{ endTimeYear }}年</span>
            <br />
            <span style="font-size: 28px">{{ endTimeDay }}</span>
          </div>
          <div style="padding-left: 24px">
            <span style="font-size: 16px">费用小结</span>
            <br />
            <span style="font-size: 18px">{{ totalCost }}</span>
          </div>
        </div>
      </template>
    </van-cell>
    <van-cell title="西药房小计" style="color: #57c4b7" />
    <van-cell style="padding-top: 4px">
      <template slot="title">
        <div>
          <div>
            药品名称
            <div style="float: right">数量/金额</div>
          </div>
        </div>
      </template>
      <template slot="label">
        <div>
          <div v-for="item in list" :key="item.index">
            <div v-if="item.itemType == '西药费'">
              {{ item.itemName }}
              <!--项目名称-->
              <div style="float: right">
                {{ item.itemNum }}/{{ item.itemPrice }}元
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-cell>
    <div style="height: 10px"></div>
    <van-cell title="化验单小计" style="color: #57c4b7" />
    <van-cell style="padding-top: 4px">
      <template slot="title">
        <div>
          <div>
            项目名称
            <div style="float: right">数量/金额</div>
          </div>
        </div>
      </template>
      <template slot="label">
        <div>
          <div v-for="item1 in list" :key="item1.index">
            <div
              v-if="item1.itemType == '化验费' || item1.itemType == '放射费'"
            >
              {{ item1.itemName }}
              <div style="float: right">
                {{ item1.itemNum }}/{{ item1.itemPrice }}元
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-cell>

    <div style="height: 10px"></div>
    <van-cell title="中药房小计" style="color: #57c4b7" />
    <van-cell style="padding-top: 4px">
      <template slot="title">
        <div>
          <div>
            药品名称
            <div style="float: right">数量/金额</div>
          </div>
        </div>
      </template>
      <template slot="label">
        <div>
          <div v-for="item2 in list" :key="item2.index">
            <div
              v-if="
                item2.itemType == '成药费' ||
                item2.itemType == '草药费' ||
                item2.itemType == '中药费'
              "
            >
              {{ item2.itemName }}
              <div style="float: right">
                {{ item2.itemNum }}/{{ item2.itemPrice }}元
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-cell>

    <div style="height: 10px"></div>
    <van-cell title="其他费用小计" style="color: #57c4b7" />
    <van-cell style="padding-top: 4px">
      <template slot="title">
        <div>
          <div>
            药品名称
            <div style="float: right">数量/金额</div>
          </div>
        </div>
      </template>
      <template slot="label">
        <div>
          <div v-for="item2 in list" :key="item2.index">
            <div
              v-if="
                item2.itemType != '诊查费' &&
                item2.itemType != '护理费' &&
                item2.itemType != '中药费' &&
                item2.itemType != '化验费' &&
                item2.itemType != '放射费' &&
                item2.itemType != '西药费'
              "
            >
              {{ item2.itemName }}
              <div style="float: right">
                {{ item2.itemType }}{{ item2.itemNum }}/{{ item2.itemPrice }}元
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-cell>
   <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import { Toast } from "vant";
import store from "../../store";

// let inpatientSeriNo = store.getters["store/inpatientSeriNo"];
let totalCost = window.sessionStorage.getItem("totalCost");
let endTimeYear = window.sessionStorage.getItem("endTimeYear");
let endTimeDay = window.sessionStorage.getItem("endTimeDay");
let dischargeDate = window.sessionStorage.getItem("dischargeDate");
// var nowDate = new Date();
export default {
  data() {
    return {
      totalCost: totalCost,
      endTimeYear: endTimeYear,
      endTimeDay: endTimeDay,
      dischargeDate: dischargeDate,
      endTime: "",
      summary: "0.08",
      list: [],
      feeDateStart: "2020",
      feeDateEnd: "",
    };
  },
  created() {
    console.log(totalCost);
    this.feeDateStart = endTimeYear + "-" + endTimeDay;
    // this.feeDateStart =
    //   endTimeYear + endTimeDay.substring(0, 2) + endTimeDay.substring(3, 5);
    // console.log(this.feeDateStart);
    // this.feeDateEnd =
    //   nowDate.getFullYear() +
    //   "" +
    //   (nowDate.getMonth() >= 9
    //     ? nowDate.getMonth() + 1
    //     : "0" + (nowDate.getMonth() + 1)) +
    //   nowDate.getDate();
    // console.log(this.feeDateEnd);
    this.getData();
  },
  methods: {
    async getData() {
      let idcard = store.getters["store/idcard"];
      let inpatientNo = store.getters["store/inpatientNo"];
      if (dischargeDate === "null") {
        dischargeDate = "";
        // console.log(123);
      } else {
         dischargeDate = dischargeDate.substring(0, 10)
      }
      // this.feeDateEnd = this.startTime =
      //   nowDate.getFullYear() +
      //   "-" +
      //   (nowDate.getMonth() >= 9
      //     ? + nowDate.getMonth() + 1
      //     : "0" + (nowDate.getMonth() + 1)) +
      //   (nowDate.getDate() >= 9
      //     ? "-" + (nowDate.getDate() + 1)
      //     : "-0" + (nowDate.getDate() + 1));
      // console.log(inpatientNo + "IN:" + inpatientSeriNo);
      await ajax
        .post("/Api/InHospitalService/GetInpatientDetail", {
          idCard: idcard,
          inpatientNo: inpatientNo, //住院号
          begin: this.feeDateStart,
          end: dischargeDate,
          machineName: "wechat",
          // idcard: "532323201709111320",
          // inpatientNo: "1814908",
          // begin: "2018-07-12",
          // end: "2019-09-12",
          // machineName: "wechat",
        })
        .then((res) => {
          const inpatientInfo = res.data.result.inpatientFees;
          console.log(inpatientInfo);
          this.list = inpatientInfo;
          // this.endTime = inpatientInfo.endTime;
          // this.summary = inpatientInfo.summary;
          // console.log(res.data.result.inpatientInfo);
          // this.list = res.data.result.inpatientFees; //住院费用详情
          // console.log(this.list);
        })
        .catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        });
    },
  },
};
</script>


<style  scoped>
.lj {
  background: #57c4b7;
}
</style>
